@import 'styles/config';

.container {
  align-items: center;
  row-gap: 32px;
  padding: 0;

  .image {
    display: inherit;
    justify-content: center;
  }
}

.notFound404 {
  &__title {
    font-weight: 800;
    font-size: 52px;
    margin-bottom: 0.4em;

    @media (min-width: $min-desktop) {
      @include responsive-font(65px, 100px, $min-desktop);
    }
  }

  &__text {
    margin: 1em 0 1.8em;
    max-width: 700px;
    color: #505050;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;

    color: $color-secondary;

    > svg {
      height: 18px;
      margin-top: 3px;
      transition: transform $transition-duration ease-in-out;
    }

    &:focus > svg,
    &:hover > svg {
      transform: translateX(-7px);
    }
  }
}
